import { PageType, UserKey } from "../App";
import "./Navigation.css";
import HomeIcon from '@mui/icons-material/Home';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PeopleIcon from "@mui/icons-material/People";
import { useGetState } from "../useBackend";
import { UserWithoutActivity } from "../DTOs";

export default function Navigation(props: {setPage: (page: PageType) => void, page: PageType, user: UserKey}) {
    
    const { response } = useGetState<UserWithoutActivity>(`/user/${props.user.secretId}`);
    
    return <div>
        
        <div className="Navigation">
            <div onClick={() => props.setPage("feed")}><HomeIcon style={iconColor(props.page, "feed")}/></div>
            <div onClick={() => props.setPage("leaderboard")}><LeaderboardIcon style={iconColor(props.page, "leaderboard")}/></div>
            <div onClick={() => props.setPage("social")}><PeopleIcon style={iconColor(props.page, "social")}/></div>
            <div onClick={() => props.setPage("settings")}><img style={{height: "50%", borderRadius: "50%"}} src={response?.profile} /></div>
        </div>

        <div className="Navigation-padding">
        </div>

    </div>;
}

function iconColor(currentPage: PageType, selfPage: PageType) {
    return {color: currentPage === selfPage ? "#31924b" : "#aaa"};
}