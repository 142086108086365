import { useEffect } from "react";
import "./Modal.css";

export default function Modal(props: { show: boolean, children: React.ReactNode }) {

    useEffect(() => {

        if (props.show) {
            window.document.body.style.height = "100vh";
            window.document.body.style.overflow = "hidden";
        }
        else {
            window.document.body.style.height = "initial";
            window.document.body.style.overflow = "initial";
        }

    }, [props.show])

    return <div className={`Modal ${props.show ? "show" : "hide"}`}>
        {props.children}
    </div>
}