import "./Leaderboard.css";
import { useGetState } from "./useBackend";
import { GroupWithoutUsers, UserWithoutActivity } from "./DTOs";
import CentralSpinner from "./components/CentralSpinner";
import { useEffect, useState } from "react";
import { notDeepEqual } from "assert";

export default function Leaderboard() {

    const { response } = useGetState<LeaderboardEntry[]>("/leaderboard");

    const { response: groupResponse } = useGetState<GroupLeaderboardEntry[]>("/leaderboard/group");

    const [leaderboardType, setLeaderboardType] = useState<"user" | "group">("user");

    const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>([]);

    useEffect(() => {

        if (leaderboardType === "user" && response !== null) {
            setLeaderboardData(response);
        }

        if (leaderboardType === "group" && groupResponse !== null) {
            setLeaderboardData(groupResponse.map(gr => ({
                total: gr.total,
                user: {
                    name: gr.group.name,
                    id: gr.group.name,
                    profile: "./white-gradient-192.png"
                }
            })))
        }

    }, [leaderboardType, response, groupResponse]);

    if (response === null || groupResponse === null) {
        return <CentralSpinner/>;
    }

    const first = leaderboardData[0];
    const second = leaderboardData[1];
    const third = leaderboardData[2];

    const rows = leaderboardData.splice(3);

    return <div className="Leaderboard">

        <div className="Leaderboard-selector">
            <div className={leaderboardType === "user" ? "selected" : ""} onClick={() => setLeaderboardType("user")}>Users</div>
            <div className={leaderboardType === "group" ? "selected" : ""} onClick={() => setLeaderboardType("group")}>Groups</div>
        </div>

        <div className="Leaderboard-podium">

            { first !== undefined && <div>
                <img className="podium-profile" src={second.user.profile ?? undefined} />
                <img className="podium-wave" src="./wave.svg" />
                <div className="second">
                    <span className="podium-total">£{second.total.toFixed(2)}</span>
                    <span className="podium-name">{second.user.name}</span>
                </div>
            </div> }

            { second !== undefined && <div>
                <img className="podium-profile" src={first.user.profile ?? undefined} />
                <img className="podium-wave" src="./wave.svg" style={{transform: "scaleX(-1)"}} />
                <div className="first">
                    <span className="podium-total">£{first.total.toFixed(2)}</span>
                    <span className="podium-name">{first.user.name}</span>
                </div>
            </div> }

            { third !== undefined && <div>
                <img className="podium-profile" src={third.user.profile ?? undefined} />
                <img className="podium-wave" src="./wave.svg" />
                <div className="third">
                    <span className="podium-total">£{third.total.toFixed(2)}</span>
                    <span className="podium-name">{third.user.name}</span>
                </div>
            </div>}
            
        </div>
        
        <div className="Leaderboard-podium-buffer"></div>

        <div className="Leaderboard-list">
            {rows.map((row, index) => <LeaderboardRow leaderboardEntry={row} index={index + 4} />)}
        </div>
    </div>
}

function LeaderboardRow(props: {leaderboardEntry: LeaderboardEntry, index: number }) {
    return <div className="Leaderboard-row">
        <span>#{props.index}</span>
        <img src={props.leaderboardEntry.user.profile ?? undefined} />
        <span><b>£{props.leaderboardEntry.total.toFixed(2)}</b></span>
        <span>{props.leaderboardEntry.user.name}</span>
    </div>
}

interface LeaderboardEntry {
    user: UserWithoutActivity;
    total: number;
}

interface GroupLeaderboardEntry {
    group: GroupWithoutUsers;
    total: number;
}