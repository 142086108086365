import "./Feed.css";
import { UserKey } from "./App";
import FeedItem from "./components/FeedItem";
import { useGetState } from "./useBackend";
import CentralSpinner from "./components/CentralSpinner";
import { ActivityWithUser, CharityWithoutActivities } from "./DTOs";

export default function Feed(props: {user: UserKey}) {

    const {response, status, refresh, refreshing} = useGetState<ActivityWithUser[]>(`/activities/${props.user.secretId}`);
    const { response: charityResponse } = useGetState<CharityWithoutActivities[]>("/charities");

    if (response === null) {
        return <CentralSpinner />
    }

    return <div className="Feed">
        {(response ?? []).map(activity => <FeedItem key={activity.id} refresh={refresh} user={props.user} activity={activity} charities={charityResponse ?? []} />)}

        {response?.length === 0 && <p style={{background: "white", textAlign: "center", padding: "4em"}}>Hm, it's quite quiet around here. Try adding a friend or joining a group.</p>}
    </div>

}