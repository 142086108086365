import { useEffect, useState } from "react";

//const HOST = "http://10.247.220.34:5052";
//const HOST = "https://origo-backend.azurewebsites.net";
//const HOST = "http://192.168.1.74:5052";
const HOST = "https://origo-backend.onrender.com";
export default function useBackend() {

    async function post<T>(path: string, body: any) {
        let json = {} as T;
        let status = 0;
        try {
            const response = await fetch(HOST + path, {method: "post", body: JSON.stringify(body), headers: {'Accept': 'application/json','Content-Type': 'application/json'}});
            status = response.status;
            json = await response.json();
        }
        catch (e) { }
        
        return { body: json, status }
    }

    async function get<T>(path: string) {
        const response = await fetch(HOST + path);
        const status = response.status;
        let json = {} as T;
        try {
            json = await response.json();
        }
        catch (e) { }
        
        return { body: json, status }
    }

    return { get, post }
}

export function usePostState<T>(path: string, body: any) {
    const [response, setResponse] = useState<T | null>(null);

    const [status, setStatus] = useState<number | null>(null);

    useEffect(() => {
       run();
    }, []);

    async function run() {
        const response = await fetch(HOST + path, {method: "post", body: JSON.stringify(body), headers: {'Accept': 'application/json','Content-Type': 'application/json'}});
        const status = response.status;
        let json = {} as T;
        try {
            json = await response.json();
        }
        catch (e) { }

        setStatus(status);
        setResponse(json);
    }

    return { response, status }
}

export function useGetState<T>(path: string) {
    const [response, setResponse] = useState<T | null>(null);
    const [status, setStatus] = useState<number | null>(null);

    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        refresh();
    }, []);

    async function refresh() {
        setRefreshing(true);
        const response = await fetch(HOST + path);
        const status = response.status;
        let json = {} as T;
        try {
            json = await response.json();
        }
        catch (e) {
        }

        setStatus(status);
        setResponse(json);
        setRefreshing(false);
    }

    return { response, status, refresh, refreshing }
}