import { useEffect, useState } from "react"
import "./App.css";
import Feed from "./Feed";
import Auth from "./Auth";
import Tally from "./components/Tally";
import Navigation from "./components/Navigation";
import Leaderboard from "./Leaderboard";
import Settings from "./Settings";
import Social from "./Social";

export type UserKey = { secretId: string, publicId: string }
export type PageType = "feed" | "leaderboard" | "settings" | "social";

export default function App() {
    const [user, setUser] = useState<UserKey>(/*{"secretId": "51e61f50-d0f0-4661-abdb-b2e96440e463", "publicId":"21db1bba-85bb-413f-ae6c-5542c94c943f" }*/);

    const [page, setPage] = useState<PageType>("feed");

    useEffect(() => {
        window.scroll(0,0);
    }, [page]);
    
    if (user === undefined) {
        return <div className="App">
            <Auth setUser={setUser} />
        </div> 
    }

    return <div className="App">
        { page === "feed" && <Tally user={user}/> }
        { page === "feed" && <Feed user={user} /> }
        { page === "leaderboard" && <Leaderboard />}
        
        { page === "settings" && <Settings setUser={setUser} />}

        { page === "social" && <Social user={user} />}


        <Navigation page={page} setPage={setPage} user={user}/>
    </div>
}
