import "./FeedItem.css";
import prettyDate from "../prettyDate";

import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { UserKey } from "../App";
import useBackend, { useGetState } from "../useBackend";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import CommentRow from "./CommentRow";
import { ActivityWithUser, CharityWithoutActivities, CommentWithoutActivity, LikeWithoutActivity } from "../DTOs";

export default function FeedItem(props: {activity: ActivityWithUser, user: UserKey, charities: CharityWithoutActivities[], refresh: () => void}) {

    const { response: likes, refresh: refreshLikes } = useGetState<LikeWithoutActivity[]>(`/likes/${props.activity.id}`);
    const { response: comments, refresh: refreshComments } = useGetState<CommentWithoutActivity[]>(`/comments/${props.activity.id}`);

    const { post } = useBackend();

    const [hasRecentlyLiked, setHasRecentlyLiked] = useState(false);

    useEffect(() => {
        setHasRecentlyLiked(false)
    }, [likes])


    const ownLike = (likes ?? []).find(like => like.owner.id === props.user.publicId);

    const [showLikes, setShowLikes] = useState(false);

    const [showComments, setShowComments] = useState(false);
    const [sendingComment, setSendingComment] = useState(false);
    const commentInputRef = useRef<HTMLInputElement>(null);

    const [sendingVerification, setSendingVerification] = useState(false);
    const charitySelectorRef = useRef<HTMLSelectElement>(null);

    const distance = `${(props.activity.activityData.distance / 1000).toFixed(1)}km`;
    const moneyRaised = `£${props.activity.moneyRaised.toFixed(2)}`;

    async function like() {

        if (ownLike === undefined) {
            const response = await post("/like", { activityId: props.activity.id, privateUserId: props.user.secretId } );
            setHasRecentlyLiked(true)
        }
        else {
            const response = await post("/like/delete", { interactionId: ownLike.id, privateUserId: props.user.secretId } );
        }

        refreshLikes();
    }

    async function comment() {

        if (commentInputRef === null || commentInputRef.current === null ) {
            return;
        }

        const text = commentInputRef.current.value;

        setSendingComment(true);
        const response = await post("/comment", { activityId: props.activity.id, privateUserId: props.user.secretId, text });
        setSendingComment(false);
        
        if (response.status === 200) {
            commentInputRef.current.value = "";
            refreshComments();
        }
    }

    function share() {
        if (navigator.share !== undefined && props.activity.charity !== null) {
            navigator.share({
                title: "Origo Activity", 
                text: `${props.activity.owner.name} raised ${moneyRaised} for ${props.activity.charity.name} by running ${distance}! Download the Origo app or visit https://origo-fundraising.org to start your own fundraising journeys!`})
        }
    }

    async function verify() {
        if (charitySelectorRef === null || charitySelectorRef.current === null ) {
            return;
        }

        setSendingVerification(true);
        const response = await post("/activity/verify", {activityId: props.activity.id, privateUserId: props.user.secretId, charityId: charitySelectorRef.current.value})
        setSendingVerification(false);

        if (response.status === 200) {
            props.refresh();
        }
    }

    if (props.activity.charity === null && props.activity.owner.id !== props.user.publicId) {
        return null;
    }

    return <div className="FeedItem">

        <Modal show={showLikes}>
            <div className="FeedItem-modal">
               
                <div className="FeedItem-modal-header" onClick={() => setShowLikes(false)}>
                    <ArrowBackIcon fontSize="large" style={{transform: "scale(1.5)"}} />
                    <h2>Likes</h2>
                </div>

                { (likes ?? []).map(like => <CommentRow key={like.id} user={like.owner} text="" date="" />) }        

            </div>
        </Modal>

        <Modal show={showComments}>
            <div className="FeedItem-modal FeedItem-comments">
               
               <div className="FeedItem-modal-header" onClick={() => setShowComments(false)}>
                   <ArrowBackIcon fontSize="large" style={{transform: "scale(1.5)"}} />
                   <h2>Comments</h2>
               </div>

            <div className="FeedItem-comments-form">
                <input disabled={sendingComment} type="text" ref={commentInputRef} />
                <button disabled={sendingComment} onClick={() => comment()}>Send</button>
            </div>
                
               
            {(comments ?? []).map(comment => <CommentRow key={comment.id} user={comment.owner} text={comment.text} date={prettyDate(comment.time)} />) }        

           </div>
        </Modal>

        <Modal show={props.activity.charity === null}>
            <div className="FeedItem-verify">
                <div className="FeedItem-modal-header">
                   <ArrowBackIcon fontSize="large" style={{transform: "scale(1.5)", opacity: 0}} />
                   <h2>Donate</h2>
                </div>
            
                <p>Great work on <b>{props.activity.activityData.name}</b>, you raised <b>£{props.activity.moneyRaised.toFixed(2)}</b> from <b>{props.activity.sponsor.name}</b>!</p>
                <br/>
                <p>Select the name of the charity you want to support with that effort.</p>

                <select disabled={sendingVerification} ref={charitySelectorRef}>
                    {props.charities.map(charity => <option key={charity.id} value={charity.id}>{charity.name}</option>)}
                </select>

                <p style={{color: "red", fontSize: "3em"}}>ADVERT HERE!</p>

                <button disabled={sendingVerification} onClick={() => verify()}>Donate</button>
            </div>
        </Modal>

        <div className="FeedItem-head">
            <img src={props.activity.owner.profile ?? undefined} />
            <div>
                <span className="FeedItem-owner-name">{props.activity.owner.name}</span>
                <span>{props.activity.charity?.name ?? "None"}</span>
                <span className="FeedItem-date-separator">•</span>
                <span className="FeedItem-date">{prettyDate(props.activity.uploadDate)}</span>
            </div>
        </div>
        <span className="FeedItem-name">{props.activity.activityData.name}</span>

        <span>{props.activity.activityData.description}</span>

        <div className="FeedItem-stats">
            <span>{distance}</span>
            <span className="FeedItem-stats-divider">|</span>
            <span>{(formatDuration(props.activity.activityData.moving_time))}</span>
            <span className="FeedItem-stats-divider">|</span>
            <span>{moneyRaised}</span>
        </div>

        <div className="FeedItem-foot">
            <a href={"https://www.strava.com/activities/" + props.activity.stravaId}>View on Strava</a>
            
                <div onClick={() => like()}>
                    <div className="icon-overlay">{(likes ?? []).length}</div>
                    <FavoriteIcon style={{color: (ownLike !== undefined || hasRecentlyLiked) ? "#ff6161" : "#aaa"}} fontSize="large" />
                </div>

                <div onClick={() => setShowComments(true)}>
                    <div style={{top: 6}} className="icon-overlay">{(comments ?? []).length}</div>
                    <ChatBubbleIcon style={{color: "#aaa"}} fontSize="large"></ChatBubbleIcon>
                </div>

                <div onClick={() => share()}>
                    <ReplyIcon style={{transform: "scaleX(-1)",color: "#aaa"}} fontSize="large" />
                </div>
        </div>
    </div>
}

function formatDuration(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = (seconds % 3600) / 60;

    return (hours > 0 ? hours + "h " : "") + minutes.toFixed(0) + "mins" 
}


export interface ActivityData {
    name: string,
    distance: number,
    description: string,
    moving_time: number,
    start_date: string
}