import "./Social.css";
import { useEffect, useState } from "react";
import { UserKey } from "./App";
import { RelativeFriendship, RelativeMembership } from "./DTOs";
import useBackend, { useGetState } from "./useBackend";
import CentralSpinner from "./components/CentralSpinner";

export default function Social(props: {user: UserKey}) {
    const { response: friendsResponse, refresh: friendsRefresh, refreshing: friendsRefreshing } = useGetState<RelativeFriendship[]>(`/friendships/${props.user.secretId}`);
    

    const { response: groupsResponse, refresh: groupsRefresh, refreshing: groupsRefreshing } = useGetState<RelativeMembership[]>(`/memberships/${props.user.secretId}`);

    const { response: managedGroupsResponse, refresh: managedGroupsRefresh, refreshing: managedGroupsRefreshing } = useGetState<RelativeMembership[]>(`/memberships/managed/${props.user.secretId}`);

    const [showSection, setShowSection] = useState<"friends" | "groups" | "manageMembers">("friends");

    const [search, setSearch] = useState("");
    
    useEffect(() => {
        setSearch("");
    }, [showSection])

    if (friendsResponse === null || groupsResponse === null) {
        return <CentralSpinner />
    }


    return <div className="Social">

        <div className="Social-selector">
            <div className={showSection === "friends" ? "selected" : ""} onClick={() => setShowSection("friends")}>Friends</div>
            <div className={showSection === "groups" ? "selected" : ""} onClick={() => setShowSection("groups")}>Groups</div>
            <div className={showSection === "manageMembers" ? "selected" : ""} onClick={() => setShowSection("manageMembers")}>Manage Groups</div>
        </div>
        
        <input className="Social-search" placeholder="Search..." type="text" value={search} onChange={(e) => setSearch(e.target.value)} />

        {showSection === "friends" && (friendsResponse ?? [])
            .sort((a, b) => {
                if (a.accepted === false && a.selfWasRequester === false) {
                    return 0;
                }
                if (a.accepted === false && a.selfWasRequester !== false) {
                    return 1;
                }
                if (a.accepted === true) {
                    return 2;
                }
                if (a.accepted === null) {
                    return 3;
                }

                return 4;
            })
            .filter(f => f.user.name.toLowerCase().includes(search.toLowerCase()))
            .map(row => <FriendRow key={row.id} requestRefresh={() => friendsRefresh()} relativeFriendship={row} user={props.user} />)}

        {showSection === "groups" && (groupsResponse ?? [])
            .sort((a, b) => {
                if (a.accepted === false) {
                    return 0;
                }
                if (a.accepted === true) {
                    return 1;
                }
                if (a.accepted === null) {
                    return 2;
                }

                return 4;
            })
            .filter(m => m.group.name.toLowerCase().includes(search.toLowerCase()))
            .map(row => <GroupRow key={row.id} requestRefresh={() => groupsRefresh()} relativeMembership={row} user={props.user} />)}

        {showSection === "manageMembers" && (managedGroupsResponse ?? [])
            .sort((a, b) => {
                if (a.accepted === false) {
                    return 0;
                }
                if (a.accepted === true) {
                    return 1;
                }
                if (a.accepted === null) {
                    return 2;
                }

                return 4;
            })
            .filter(m => m.group.name.toLowerCase().includes(search.toLowerCase()) || m.requester?.name.toLowerCase().includes(search.toLowerCase()))
            .map(row => <GroupManagementRow key={row.id} requestRefresh={() => managedGroupsRefresh()} relativeMembership={row} user={props.user} />)}

        { showSection === "manageMembers" && managedGroupsResponse?.length === 0 && <p style={{textAlign: "center", padding: "4em"}}>If you are a group leader your members will appear here.</p>}
    </div>
}

function GroupManagementRow(props: {relativeMembership: RelativeMembership, user: UserKey, requestRefresh: () => void}) {

    const [requestPending, setRequestPending] = useState(false);
    const { post } = useBackend();

    async function acceptToGroup() {
        setRequestPending(true);
        const response = await post(`/memberships/accept`, { groupOwnerSecretId: props.user.secretId, id: props.relativeMembership.id });
        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }

    async function leaveGroup() {
        setRequestPending(true);
        const response = await post(`/memberships/remove`, { id: props.relativeMembership.id });
        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }

    return <div className="GroupRow">

        <div className="GroupRow-name">
            <b>{props.relativeMembership.requester?.name}</b>
            {props.relativeMembership.accepted ? " is in " : " wants to join "}
            <b>{props.relativeMembership.group.name}</b>
        </div>

        {props.relativeMembership.accepted === false && props.relativeMembership.group.owner.id === props.user.publicId && <div>
            <button disabled={requestPending} onClick={() => acceptToGroup()}>Accept</button>
            <button disabled={requestPending} onClick={() => leaveGroup()}>Reject</button>
        </div>}

        {props.relativeMembership.accepted === true && <button disabled={requestPending} onClick={() => leaveGroup()}>Remove</button>}

    </div>
}

function GroupRow(props: {relativeMembership: RelativeMembership, user: UserKey, requestRefresh: () => void}) {
    
    const [requestPending, setRequestPending] = useState(false);

    const { post } = useBackend();

    async function joinGroup() {
        setRequestPending(true);
        const response = await post(`/memberships/add`, {requesterSecretId: props.user.secretId, groupId: props.relativeMembership.group.id});

        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }
    
    async function leaveGroup() {
        setRequestPending(true);
        const response = await post(`/memberships/remove`, { id: props.relativeMembership.id });
        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }

    

    return <div className="GroupRow">
        
        <div className="GroupRow-name"> {props.relativeMembership.requester !== null && <><b>{props.relativeMembership.requester.id === props.user.publicId ? "You" : props.relativeMembership.requester.name}</b> {props.relativeMembership.accepted ? " is in " : " wants to join "}</> } <b>{props.relativeMembership.group.name}</b></div>

        {props.relativeMembership.accepted === null && <button disabled={requestPending} onClick={() => joinGroup()}>Join group</button>}

        {props.relativeMembership.accepted === true && <button disabled={requestPending} onClick={() => leaveGroup()}>Leave group</button>}

        {props.relativeMembership.accepted === false && props.relativeMembership.group.owner.id !== props.user.publicId && <button disabled>Pending</button>}
        
        

    </div>
}

function FriendRow(props: {relativeFriendship: RelativeFriendship, user: UserKey, requestRefresh: () => void}) {

    const { post } = useBackend();

    const [requestPending, setRequestPending] = useState(false);

    async function addFriend() {
        setRequestPending(true);
        const response = await post(`/friendships/add`, {requesterSecretId: props.user.secretId, receiverPublicId: props.relativeFriendship.user.id});

        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }

    async function removeFriend() {
        setRequestPending(true);
        const response = await post(`/friendships/remove`, { id: props.relativeFriendship.id });
        setRequestPending(false);

        if (response.status === 200) {
            props.requestRefresh();
        }
    }

    return <div className="FriendRow">

        <img src={props.relativeFriendship.user.profile} />

        <div className="FriendRow-name"><b>{props.relativeFriendship.user.name}</b></div>

        {props.relativeFriendship.accepted === null && <button onClick={() => addFriend()} disabled={requestPending}>Add friend</button>}

        {props.relativeFriendship.accepted === true && <button onClick={() => removeFriend()} disabled={requestPending}>Remove friend</button>}
        
        {props.relativeFriendship.accepted === false && props.relativeFriendship.selfWasRequester === true && <button disabled>Pending</button>}

        {props.relativeFriendship.accepted === false && props.relativeFriendship.selfWasRequester === false && <div>
            <button onClick={() => addFriend()} disabled={requestPending}>Accept</button>
            <button onClick={() => removeFriend()} disabled={requestPending}>Reject</button>
        </div>}
    </div>
}