import { UserWithoutActivity } from "../DTOs";
import "./CommentRow.css";

export default function CommentRow(props: { user: UserWithoutActivity, text: string, date: string } ) {
    return <div className="CommentRow">
        <img src={props.user.profile ?? undefined} /> 
        
        <div>
            <span className="CommentRow-date">{props.date}</span>
            <span><b>{props.user.name}</b> {props.text}</span>
        </div>

    </div>
}
