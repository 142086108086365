import "./Tally.css";

import { UserKey } from "../App";
import { useGetState } from "../useBackend";

export default function Tally(props: {user: UserKey}) {
    
    const { response: total } = useGetState<number>("/leaderboard/ticker");
    const { response: selfTotal} = useGetState<number>(`/user/raised/${props.user.publicId}/`);

    return <div>
        <div className="Tally-padding"></div>
        <div className="Tally">
            <div>
                <span className="Tally-money">£{(total ?? 0).toFixed(2)}</span>
                <span className="Tally-name">Total donated</span> 
            </div>

            <img src="./white-transparent-icon.svg" />

            <div>
                <span className="Tally-money">£{(selfTotal ?? 0).toFixed(2)}</span>
                <span className="Tally-name">Personally donated</span> 
            </div>
        </div>
    </div>;
}