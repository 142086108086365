import { UserKey } from "./App";
import "./Settings.css";

export default function Settings(props: {setUser: (user: undefined) => void}) {

    function logout() {
        localStorage.removeItem("user");
        props.setUser(undefined);
    }

    return <div className="Settings">

        <h2>
            Account
        </h2>

        <button onClick={() => logout()}>Log out</button>
    </div>
}